<template>
  <div class="join">
    <div class="login_bg">
      <img src="@/assets/images/login_bg.jpg">
    </div>
    <div class="baseBox">
      <div class="joinBox reset">
        <h1>
          {{ $t("alert-message-sending-email") }}
        </h1>
        <h3>
          {{ $t("alert-message-confirm-email") }}<br />
          {{ $t("alert-message-info-find-pw") }}
        </h3>
        <div class="buttonWrap">
            <button class="point large" @click="onLogin">{{ $t("btn-login-done") }}</button>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<style>
  .reset > h1{font-size: 1.6rem;}
  .reset > h3{font-size: 1.2rem;color:#969696;}
  .buttonWrap > button{background: #0080FF;border: 0;border-radius: 5px;}
</style>
<script>
import Footer from "@/components/Footer";
import router from "../../router";

export default {
  components: { Footer },
  data() {
    return {
      accountId: "",
      device : null,
    };
  },
  mounted() {
    this.accountId = this.$route.params.accountId;
    this.device = this.$route.query.device;
  },
  methods: {
    onLogin(){
      if(this.device != undefined && this.device != null && this.device != "") {
        window.Android.reLogin("로그인 이동");
        return;
      }
      router.push({path : `/${this.accountId}/login`})
      document.location.href = `/${this.accountId}/login`;
    },
  }

};
</script>
